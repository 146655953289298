<template>
  <div class="content-box">
    <main class="content-main">
      <!-- title -->
      <div class="first-level">
        <h1>{{ type === 'btcinfo' ? '新闻资讯' : '数据分析' }}</h1>
      </div>
      <!-- card-box -->
      <div class="card-item-box">
        <section-card v-for="item in fourList" :key="item.NewsID" :data="item" :is-en-expire="isInfoEnExpire" :show-video="item > 2" :show-flag="item > 2" />
      </div>
      <!-- sub title -->
      <div class="second-level">
        <h2>{{ title }}</h2>
        <div>
           <picker :key="0" :is-show="isShow" :data="type === 'btcinfo' ? typeList : analysisTypeList" :title="title" @click.native="handleSelector" @tap-item="handleTabItem"/>
           <picker :key="1" :is-show="isSelected" :data="listCountry" :title="title1"  @click.native="handleSelector1" @tap-item="handleTabItem2"/>
          <div>
            <input v-model="listQuery.KeyWords" type="text">
            <img src="@/assets/image/btceyes/search.png" style="width: 25px; height: 25px;" @click="getNewsList(false)">
          </div>
        </div>
      </div>
      <!-- card-box -->
      <div class="second-card-item-box">
        <info-card v-for="(item, index) in list" :key="index" :item="item" :is-en-expire="isInfoEnExpire" :class="{ 'bottom-line': index % 2 === 0 }" class="info-card" />
      </div>
      <!-- pagination -->
      <div class="pagination-box">
        <pagination :total="total" :page.sync="listQuery.PageNum" :size.sync="listQuery.PageSize" @pagination="getNewsList(false)"/>
      </div>
    </main>
  </div>
</template>

<script>
import InfoCard from './components/info-card'
import Picker from './components/picker'
import SectionCard from './components/section-card'
import Pagination from '@/components/pagination'
import commonmix from '@/utils/commonmix'
// import dict from '@/utils/dict.js'

export default {
  mixins: [commonmix],
  name: 'CnBTCInfoMore',
  components: {
    Picker,
    InfoCard,
    SectionCard,
    Pagination
  },
  data () {
    return {
      list: [],
      listCountry: [],
      total: 0,
      listQuery: {
        PageNum: 1,
        PageSize: 10,
        KeyWords: '',
        NewsLanguageCode: 'zh-CN',
        Countury: ''
      },
      fourList: [],
      isInfoEnExpire: false,
      typeList: [
        { label: '全部', value: 'btcinfo' },
        { label: '必孚牛肉', value: '_beefnewsandevents' },
        { label: '必孚猪肉', value: '_porknews' },
        { label: '必孚多肉', value: '_beefpicked' }
      ],
      analysisTypeList: [
        { label: '全部', value: 'btcanalysis' },
        { label: '牛肉', value: '_beefdatanalysis' },
        { label: '猪肉', value: '_porkdatanalysis' }
      ],
      porkCountryArray: [
        { label: '国别', value: '_all' },
        { label: '阿根廷', value: '_argentina' },
        { label: '爱尔兰', value: '_ireland' },
        { label: '巴西', value: '_brazil' },
        { label: '丹麦', value: '_denmark' },
        { label: '德国', value: '_germany' },
        { label: '法国', value: '_france' },
        { label: '芬兰', value: '_finland' },
        { label: '荷兰', value: '_netherlands' },
        { label: '加拿大', value: '_canada' },
        { label: '美国', value: '_unitedstates' },
        { label: '葡萄牙', value: '_portugal' },
        { label: '西班牙', value: '_spain' },
        { label: '匈牙利', value: '_hungary' },
        { label: '英国', value: '_unitedkingdom' },
        { label: '智利', value: '_chile' },
        { label: '墨西哥', value: '_mexico' },
        { label: '塞尔维亚', value: '_serbia' },
        { label: '奥地利', value: '_austria' },
        { label: '中国（港澳台地区除外）', value: '_china' },
        { label: '哥斯达黎加', value: '_costarica' },
        { label: '瑞士', value: '_switzerland' },
        { label: '意大利', value: '_italy' },
        { label: '波兰', value: '_poland' },
        { label: '比利时', value: '_belgium' },
        { label: '罗马尼亚', value: '_romania' },
        { label: '其他', value: '_other' }
      ],
      countryArray: [
        { label: '国别', value: '_all' },
        { label: '阿根廷', value: '_argentina' },
        { label: '澳大利亚', value: '_australia' },
        { label: '白俄罗斯', value: '_belarus' },
        { label: '玻利维亚', value: '_bolivia' },
        { label: '巴西', value: '_brazil' },
        { label: '加拿大', value: '_canada' },
        { label: '智利', value: '_chile' },
        { label: '中国（港澳台地区除外）', value: '_china' },
        { label: '哥斯达黎加', value: '_costarica' },
        { label: '法国', value: '_france' },
        { label: '匈牙利', value: '_hungary' },
        { label: '爱尔兰', value: '_netherlands' },
        { label: '哈萨克斯坦', value: '_kazakhstan' },
        { label: '墨西哥', value: '_mexico' },
        { label: '蒙古国', value: '_mongolia' },
        { label: '纳米比亚', value: '_namibia' },
        { label: '荷兰', value: '_netherlands' },
        { label: '新西兰', value: '_newzealand' },
        { label: '巴拿马', value: '_panama' },
        { label: '塞尔维亚', value: '_serbia' },
        { label: '南非', value: '_southafrica' },
        { label: '美国', value: '_unitedstates' },
        { label: '乌拉圭', value: '_uruguay' },
        { label: '乌克兰', value: '_ukraine' },
        { label: '立陶宛', value: '_lithuania' },
        { label: '英国', value: '_unitedkingdom' },
        { label: '拉脱维亚', value: '_latvia' },
        { label: '其他', value: '_other' }
      ],
      current: 0,
      current2: 0,
      isShow: false,
      isSelected: false
    }
  },
  computed: {
    type () {
      const type1 = this.typeList.map(v => {
        return v.value
      })
      if (type1.includes(this.$route.query.type)) {
        return 'btcinfo'
      }
      return 'btcanalysis'
    },
    title () {
      if (this.type === 'btcinfo') {
        return this.typeList[this.current].label
      } else if (this.type === 'btcanalysis') {
        return this.analysisTypeList[this.current].label
      }
      return ''
    },
    title1 () {
      if (this.type === 'btcinfo') {
        if (this.typeList[this.current].value === '_beefnewsandevents') {
          return this.countryArray[this.current2].label
        } else if (this.typeList[this.current].value === '_porknews' || this.typeList[this.current].value === '_beefpicked') {
          return this.porkCountryArray[this.current2].label
        } else if (this.typeList[this.current].value === 'btcinfo') {
          return '全部'
        } else if (this.typeList[this.current].value === '_beefpicked') {
          return this.porkCountryArray[this.current2].label
        }
      } else if (this.type === 'btcanalysis') {
        if (this.analysisTypeList[this.current].value === '_beefdatanalysis') {
          return this.countryArray[this.current2].label
        } else if (this.analysisTypeList[this.current].value === '_porkdatanalysis') {
          return this.porkCountryArray[this.current2].label
        } else if (this.analysisTypeList[this.current].value === 'btcanalysis') {
          return '全部'
        }
      }
      return ''
    }
  },
  updated () {
    // 回车登录
    document.onkeyup = (e) => {
      if (e.keyCode === 13) {
        this.getNewsList(true)
      }
    }
  },
  mounted () {
    this.getNewsList(true)

    console.log('------')
    console.log(this.type)
    console.log('------')
    if (this.type === 'btcinfo') {
      this.isInfoEnExpire = !this.getUserProfile(this.$t('message._beefpicked')) && this.getUserProfile(this.$t('message._beefnewsandevents')) && this.getUserProfile(this.$t('message._newsanalysis'))
      console.log(this.isInfoEnExpire)
    } else if (this.type === 'btcanalysis') {
      // eslint-disable-next-line no-debugger
      // debugger
      this.isInfoEnExpire = !this.getUserProfile(this.$t('message._btcdataanalysis'))
    }
  },
  methods: {
    getNewsList (isFirst) {
      if (isFirst) {
        if (this.type === 'btcinfo') {
          this.listQuery.Type = 'btcinfo'
          if (this.typeList[this.current].value === '_beefnewsandevents') {
            this.listQuery.Countury = this.countryArray[this.current2].value
          } else if (this.typeList[this.current].value === '_porknews' || this.typeList[this.current].value === '_beefpicked') {
            this.listQuery.Countury = this.porkCountryArray[this.current2].value
          }
        } else if (this.type === 'btcanalysis') {
          this.listQuery.Type = 'btcanalysis'
          if (this.analysisTypeList[this.current].value === '_beefdatanalysis') {
            this.listQuery.Countury = this.countryArray[this.current2].value
          } else if (this.analysisTypeList[this.current].value === '_porkdatanalysis') {
            this.listQuery.Countury = this.porkCountryArray[this.current2].value
          }
        }
      } else {
        if (this.type === 'btcinfo') {
          this.listQuery.Type = this.typeList[this.current].value
          if (this.typeList[this.current].value === '_beefnewsandevents') {
            this.listQuery.Countury = this.countryArray[this.current2].value
          } else if (this.typeList[this.current].value === '_porknews' || this.typeList[this.current].value === '_beefpicked') {
            this.listQuery.Countury = this.porkCountryArray[this.current2].value
          }
        } else if (this.type === 'btcanalysis') {
          this.listQuery.Type = this.analysisTypeList[this.current].value
          if (this.analysisTypeList[this.current].value === '_beefdatanalysis') {
            this.listQuery.Countury = this.countryArray[this.current2].value
          } else if (this.analysisTypeList[this.current].value === '_porkdatanalysis') {
            this.listQuery.Countury = this.porkCountryArray[this.current2].value
          }
        }
      }
      this.$store.dispatch('GetBtcInfoNewsBySection', this.listQuery).then(() => {
        this.list = this.$store.getters.newsRows
        this.total = this.$store.getters.newsTotal
        if (isFirst) {
          this.fourList = this.list.slice(0, 4)
        }
      })
    },
    handleSelector () {
      this.isShow = !this.isShow
    },
    handleSelector1 () {
      this.isSelected = !this.isSelected
    },
    handleTabItem (index) {
      this.current = index
      this.listCountry = []
      if (this.type === 'btcinfo') {
        if (this.typeList[this.current].value === '_beefnewsandevents') {
          this.current2 = 0
          this.listCountry = this.countryArray
        } else if (this.typeList[this.current].value === '_porknews') {
          this.current2 = 0
          this.listCountry = this.porkCountryArray
        } else if (this.typeList[this.current].value === 'btcinfo') {
          this.listCountry = ''
          this.current2 = 0
        } else if (this.typeList[this.current].value === '_beefpicked') {
          this.current2 = 0
          this.listCountry = this.porkCountryArray
        }
      } else if (this.type === 'btcanalysis') {
        if (this.analysisTypeList[this.current].value === '_beefdatanalysis') {
          this.current2 = 0
          this.listCountry = this.countryArray
        } else if (this.analysisTypeList[this.current].value === '_porkdatanalysis') {
          this.current2 = 0
          this.listCountry = this.porkCountryArray
        } else if (this.typeList[this.current].value === 'btcanalysis') {
          this.listCountry = ''
          this.current2 = 0
        }
      }
      this.getNewsList(false)
    },
    handleTabItem2 (index) {
      this.current2 = index
      this.getNewsList(false)
    }
  }
}
</script>

<style scoped>
.content-box {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.content-main {
  width: 1248px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 10px;
}

.content-main > .first-level {
  height: 60px;
  background-color: #ffffff;
  margin: 20px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.content-main > .first-level > h1 {
  font-size: 30px;
  font-weight: 400;
  margin: 0;
}

.card-item-box {
  display: flex;
}

.content-main > .second-level {
  /* height: 40px; */
  background-color: #ffffff;
  margin: 20px 0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.content-main > .second-level > h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.content-main > .second-level > div {
  display: flex;
  align-items: center;
}

.content-main > .second-level > div > div:last-child {
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  padding: 3px;
}
.content-main > .second-level > div > div:last-child > input {
  height: 24px;
  outline: none;
  border: none;
  border-radius: 12px 0 0 12px;
  padding-left: 10px;
  background: #f5f5f5;
  font-size: 12px;
}
.content-main > .second-level > div > div:last-child > img {
  margin-left: 5px;
}

.info-card {
  width: 50%;
  background-color: #ffffff;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.bottom-line {
  border-bottom: 1px solid #e0e0e0;
}

.second-card-item-box {
  display: flex;
  flex-flow: wrap;
}

.pagination-box {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-top: 20px;
}
</style>
